import ChatListItemPrivate from "./chatListItemPrivate";
import {
    Box,
    Divider,
    Typography,
} from "@mui/material";

export default function ChatListPrivate(props) {
    // This is a list of summarized chat infos from which you decide who to chat with

    const excludeDuplicateChannel = (ownerUid, channelType) => {
        return (ownerUid !== props.myProfile.uid && channelType === "private") || channelType === "group";
    };

    return (
        <div className="tab-content scroller-section sidebar-menu-section">
            <div className="tab-pane fade active show">
                <ul id="chat-list" className="nav flex-column chat-menu" />

                <Divider />
                {props.chatList.length === 0 &&
                    <Box sx={{ p: 2 }}>
                        <Typography>To start new conversations, please go to our
                            <a href="https://b64.homester.hk/matching.html" target="_blank"> matching
                                page.</a>
                        </Typography>
                    </Box>}
                {props.chatList
                    .filter(info => excludeDuplicateChannel(info.userInfoList[0].uid, info.channelInfo.channelType))
                    .map((info, i) =>
                        <>
                            <ChatListItemPrivate
                                key={i} userInfo={info.userInfoList[0]}
                                channelInfo={info.channelInfo}
                                onClick={props.onClickItem}
                                currentChannel={props.currentChannel}
                            />
                            <Divider />
                        </>,
                    )}
            </div>
        </div>
    );
}
