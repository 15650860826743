const drawerWidth = 368;
const styles = {
    appbar: {
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: "#EBEBEB",
    },
    temporaryDrawer: {
        display: {
            xs: "block",
            sm: "none",
        },
        "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
        },
    },
    permanentDrawer: {
        display: {
            xs: "none",
            sm: "block",
        },
        "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
        },
    },
    navBox: {
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
    },
    mainbar: {
        flexGrow: 1,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
    },
};

export default styles;
