import * as React from 'react';
import Box from "@mui/material/Box";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

export default function ProfileMemberList(props) {
    function MemberItem(props) {
        return (
            <>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar src={props.imageLink}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={props.profileName}
                        secondary={
                            <React.Fragment>
                                {props.caption}
                            </React.Fragment>
                        }
                    />
                    <Box sx={{p:1.25}}>
                        {props.role}
                    </Box>
                </ListItem>
                <Divider variant="inset" component="li"/>
            </>
        )
    }

    return (
        <List >
            {props.memberInfoList.map((info, i) => <MemberItem key={i} {...info}/>)}
        </List>
    );
}
