import { useEffect, useRef } from "react";
import ChatMessagePrivate from "./chatMessagePrivate";

export default function ChatMessageList(props) {
    // This is the container for messages in a chatroom
    useEffect(() => {
        scrollToBottom();
    }, [props.chatMessages]);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ block: "end" });
    };

    function DummyMessageTop() {
        // the div below is a dummy to be displayed under the top profile
        return (
            <div>
                <br />
                <br />
                <br />
                <br />
            </div>
        );
    }

    function DummyMessageBottom() {
        // the div below is a dummy to be displayed under the top profile
        return (
            <div>
                <br />
                <br />
                <br />
            </div>
        );
    }

    return (
        <div id="chat-messages" className="scrollerchat p-3 scroller-section">
            <DummyMessageTop />
            {props.chatMessages &&
                props.chatMessages.map((row, i) => (
                    <ChatMessagePrivate
                        key={i}
                        message={row.message}
                        myProfile={props.myProfile}
                        objectProfile={props.objectProfile}
                        onReplyInvitation={props.onReplyInvitation}
                    />
                ))}
            <DummyMessageBottom />
            <div ref={messagesEndRef} />
        </div>
    );
}
