export function pushMessageToChannel(currentMessages, newMessage, currentChannel) {
    const {
        message,
        type,
    } = newMessage;

    let newCurrentMessages = [...currentMessages];

    switch (type) {
        case "message":
            if (currentChannel === message.channel) {
                newCurrentMessages.push(newMessage);
            }
            break;
        case "invitation":
            if (currentChannel === message.channel) {
                newCurrentMessages = [...currentMessages, newMessage];
            }
            break;
        case "invitationReply":
            if (currentChannel === message.channel) {
                for (let i = 0; i < newCurrentMessages.length; i++) {
                    if (newCurrentMessages[i].message.type === "invitation") {
                        newCurrentMessages[i].message.type = "invitationReply";
                        newCurrentMessages[i].message.text = newMessage.text;
                    }
                }
            }
            break;
    }
    return newCurrentMessages;
}

export function updateChatList(chatList, channel, channelProperties, userProperties) {
    let chatIndex = chatList.findIndex(chat => chat.channelInfo.channel === channel);

    if (chatIndex !== -1) {
        let newChatList = [...chatList];

        if (channelProperties.unseenCount != null || channelProperties.lastMessage) {
            // special: -1 of unseenCount is adding 1 to the current value
            if (channelProperties.unseenCount != null) {
                if (channelProperties.unseenCount === -1) {
                    channelProperties.unseenCount = newChatList[chatIndex].channelInfo.unseenCount + 1;
                }
            }

            newChatList[chatIndex].channelInfo = {
                ...newChatList[chatIndex].channelInfo, ...channelProperties,
            };
        }

        let userInfoList = newChatList[chatIndex].userInfoList;
        if (userProperties.presence != null) {
            for (let i = 0; i < userInfoList.length; i++) {
                userInfoList[i] = {
                    ...userInfoList[i],
                    presence: userProperties.presence,
                };
                // all online set to the same for all user
                // works for private chat, but needs change when for bigger group
            }
        }

        if (userProperties.lastOnline) {
            for (let i = 0; i < userInfoList.length; i++) {
                userInfoList[i].lastOnline = userProperties.lastOnline;
            }
        }

        newChatList[chatIndex] = {
            ...newChatList[chatIndex],
            userInfoList,
        };
        return newChatList;
    }
    return [];
}
