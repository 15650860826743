const onPostMessage = (res, callback) => {
    const message = res.content;
    const row = {
        message,
        type: "message",
        error: res.error,
    };
    callback(row);
};

const onReplyInvitation = (res, callback) => {
    const message = {
        ...res.content,
        type: "invitation",
    };
    const row = {
        message,
        type: "invitationReply",
        error: res.error,
    };
    callback(row);
};

module.exports = {
    onPostMessage,
    onReplyInvitation,
};
