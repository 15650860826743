import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./redux/store";

import Admin from "./components/admin/admin";
import Chatroom from "./components/chatroom.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Chatroom />} />
                    <Route path="admin" element={<Admin />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);
