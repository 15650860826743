import { useCallback, useState } from "react";

import SendIcon from "@mui/icons-material/Send";
import { Container, Divider, Grid, Tooltip } from "@mui/material";

import "../../assets/css/custom.css";
import "../../assets/vendors/jquery-ui/jquery-ui.min.css";
import "../../assets/vendors/jquery-ui/jquery-ui.theme.min.css";
import "../../assets/vendors/bootstrap/css/bootstrap.min.css";
import "../../assets/vendors/feather-font/css/iconfont.css";
import "../../assets/css/navbar.css";
import "../../assets/vendors/fontawesome/css/all.min.css";
import "../../assets/css/main.css";
import EmojiListOpener from "./emojiListOpener";

export default function ChatMessageInput(props) {
    // This is the area of input (emoji/image/text/submit) component for a chatroom

    const [textInput, setTextInput] = useState("");

    const onChangeInput = useCallback(
        (e) => {
            setTextInput(e.target.value);
        },
        [textInput]
    );

    const onSubmit = (text) => {
        props.onSubmit(text);
        setTextInput("");
    };

    const onKeyPressEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            onSubmit(textInput);
        }
    };

    const onPressSubmit = () => onSubmit(textInput);

    const addEmojiToText = (emoji) => setTextInput(textInput + emoji);

    const divStyle = {
        display: "inline-block",
        position: "fixed",
        bottom: "0",
        zIndex: 1,
        width: "100%",
        paddingRight: "25px",
        backgroundColor: "white",
    };

    return (
        <div style={divStyle}>
            <Divider />
            <Grid sx={{ p: 1 }} container spacing={2}>
                <Grid
                    item
                    xs={0.75}
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                    }}
                >
                    <Container left sx={{ p: 1 }}>
                        <EmojiListOpener addEmojiToText={addEmojiToText} />
                    </Container>
                    {/* <EmojiListOpener addEmojiToText={addEmojiToText} /> */}
                </Grid>
                <Grid
                    item
                    xs={8}
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <form id="chat-form">
                        <input
                            type="text"
                            className="form-control mr-2 border-0 rounded-0 h-100"
                            placeholder="Type message here ..."
                            onChange={onChangeInput}
                            value={textInput}
                            onKeyPress={onKeyPressEnter}
                        />
                    </form>
                </Grid>
                <Grid item xs={1}>
                    <Container left sx={{ p: 1 }}>
                        <Tooltip title="Send message">
                            <a href="#">
                                <SendIcon onClick={onPressSubmit} />
                            </a>
                        </Tooltip>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
}
