import {useState} from 'react';
import {
    Button,
    Divider,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Tooltip,
} from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

export default function InvitationPrivateDialog(props) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Tooltip title="Pair roommate">
                <a href="#" onClick={handleClickOpen}>
                    <PersonAddAlt1Icon/>
                </a>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {"Pair Roommate (Individual)"}
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText>
                        You are going to send a message inviting him/her to be your
                        roommate (for which he/she can click to confirm or reject). Continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={props.onSubmit} autoFocus>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
