import {styled} from "@mui/material/styles";
import {Dialog} from "@mui/material";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
        width: 500,
        minWidth: 200,
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
        width: 500,
        minWidth: 200,
    },
}));

export default BootstrapDialog;
