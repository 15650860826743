// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.user-icon {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;

    vertical-align: middle;
}

.chat-list-item-clicked {
    background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/custom.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;;IAElB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["img.user-icon {\n    width: 50px;\n    height: 50px;\n    object-fit: cover;\n    border-radius: 50%;\n\n    vertical-align: middle;\n}\n\n.chat-list-item-clicked {\n    background-color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
