import { useCallback, useState } from "react";
import Box from "@mui/material/Box";

export default function LoginForm(props) {
    // This is a login form intended only for convenient debug purpose
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const onChangeInputUsername = useCallback(
        (e) => {
            setUsername(e.target.value);
        },
        [username],
    );

    const onChangeInputPassword = useCallback(
        (e) => {
            setPassword(e.target.value);
        },
        [password],
    );

    const onSubmit = () => {
        setUsername("");
        setPassword("");

        if (username.length > 0 && password.length > 0) {
            props.onSubmit(username, password);
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            <form id="form-login" method="POST">
                <div className="form-group">
                    <input type="text" name="username" className="form-control" placeholder="E-Mail"
                        onChange={onChangeInputUsername} value={username} />
                    <input type="password" name="password" className="form-control"
                        placeholder="password"
                        onChange={onChangeInputPassword} value={password} />
                </div>
                <input type="button"
                    className="btn btn-primary btn-md redial-rounded-circle-50 btn-block"
                    value="Login"
                    onClick={onSubmit} />
            </form>
        </Box>
    );
}
