const axios = require('axios'); // legacy way

const formatAPIPath = (directory) => {
    const port = process.env.REACT_APP_BACKEND_PORT;
    const domain = process.env.REACT_APP_BACKEND_DOMAIN;
    return `https://${domain}:${port}${directory}`;
};

const getRequest = async (apiPath) => {
    try {
        const response = await axios.get(apiPath, { withCredentials: true });
        return response.data;
    } catch (e) {
        console.error(e);
        return {};
    }
};

const postRequest = async (apiPath, data) => {
    try {
        const response = await axios.post(apiPath, data);
        return response.data;
    } catch (e) {
        console.error(e);
        return {};
    }
};

async function getByAuthChannel(token, channel, directory) {
    const path = formatAPIPath(`${directory}?token=${token}&channel=${channel}`);
    return getRequest(path);
}

async function postByAuthChannel(token, channel, directory) {
    const data = {
        token,
        channel,
    };

    const path = formatAPIPath(directory);
    return postRequest(path, data);
}

module.exports = {
    formatAPIPath,
    getRequest,
    postRequest,
    getByAuthChannel,
    postByAuthChannel,
};
