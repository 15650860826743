import {useState} from "react";
import {
    Avatar,
    Box,
    DialogContent,
    Grid,
    Typography,
} from "@mui/material";

import BootstrapDialog from "./utils/bootstrapDialog";
import BootstrapDialogTitle from "./utils/bootstrapDialogTitle";

export default function UserProfile(props) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <a href="#" onClick={handleClickOpen}>
                <Typography variant="subtitle2">{props.profileName}</Typography>
            </a>
            <BootstrapDialog onClose={handleClose} open={open}>
                <BootstrapDialogTitle onClose={handleClose}>
                    User Info
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={{p: 1}}>
                        <Grid container spacing={2}>
                            <Grid item lg={2}>
                                <Avatar
                                    alt="group icon"
                                    src={props.imageLink}
                                    sx={{width: 64, height: 64}}
                                />
                            </Grid>
                            <Grid item lg={10}>
                                <Typography variant="subtitle2">{props.profileName}</Typography>
                                <Typography variant="caption">
                                    <a href={props.profileLink} target="_blank">profile link</a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </Box>
    );
}
