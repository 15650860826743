const onLogin = (res, callback) => {
    const { token } = res.content;

    if (token) {
        callback(token);
    }
};

const onUserPresenceChanged = (res, callback) => {
    const {
        uid,
        channel,
        presence,
        lastOnline,
    } = res.content;

    callback({
        channel,
        uid,
        presence,
        lastOnline,
    });
};

module.exports = {
    onLogin,
    onUserPresenceChanged,
};
