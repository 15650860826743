import {useState} from "react";
import {
    Avatar,
    Box,
    Container,
    Divider,
    DialogContent,
    Grid,
    Typography,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import ProfileMemberList from "./profileMemberList";
import BootstrapDialog from "./utils/bootstrapDialog";
import BootstrapDialogTitle from "./utils/bootstrapDialogTitle";

export default function ChatroomProfileMenu(props) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <a href="#" onClick={handleClickOpen}>
                <Typography variant="subtitle2">{props.profileName}</Typography>
            </a>
            <BootstrapDialog onClose={handleClose} open={open}>
                <BootstrapDialogTitle onClose={handleClose}>
                    Group Info
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={{p: 1}}>
                        <Grid container spacing={2}>
                            <Grid item lg={2}>
                                <Avatar
                                    alt="group icon"
                                    src={props.memberInfoList[0].imageLink}
                                    sx={{width: 64, height: 64}}
                                />
                            </Grid>
                            <Grid item lg={10}>
                                <Typography variant="subtitle2">{props.profileName}</Typography>
                                <Typography variant="caption">{`${props.memberInfoList.length} members`}</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Divider/>

                    <Box sx={{p: 1}}>
                        <Grid container spacing={2}>
                            <Grid item lg={2}>
                                <Container>
                                    <GroupIcon/>
                                </Container>
                            </Grid>

                            <Grid item lg={4}>
                                <Typography>{`${props.memberInfoList.length} MEMBERS`}</Typography>
                            </Grid>
                        </Grid>

                        <ProfileMemberList memberInfoList={props.memberInfoList}/>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </Box>
    );
}
