const {
    getByAuthChannel,
    postByAuthChannel,
} = require("./request.handler");

const emitRequest = (socket, requestForm) => {
    socket.emit(requestForm.event, requestForm);
};

const getRequestForm = (event, token, content) => {
    return {
        content,
        datetime: new Date(),
        event,
        token,
    };
};

const validateTextMessage = (text) => {
    if (text && text.length > 0) {
        return text.trim().length > 0;
    }
    return false;
};

const doGetChatList = (token) => {
    const directory = "/chatroom/profile/member/all";
    return getByAuthChannel(token, "", directory);
};

const doGetChatHistory = (token, channel) => {
    const directory = "/chatroom/history";
    return getByAuthChannel(token, channel, directory);
};

const doGetUserProfile = (token) => {
    const directory = "/chatroom/profile/member/one";
    return getByAuthChannel(token, "", directory);
};

const doResetUnseenCount = (token, channel) => {
    const directory = "/chatroom/profile/unseen/reset";
    return postByAuthChannel(token, channel, directory);
};

const doPostMessage = (websocket, token, channel, chatboxText) => {
    if (validateTextMessage(chatboxText)) {
        const text = chatboxText.trim();
        const content = {
            channel,
            text,
            type: "message",
            datetime: new Date(),
        };
        const request = getRequestForm("chat.postMessage", token, content);

        emitRequest(websocket, request);
    } else {
        console.error("Cannot get input!");
    }
};

const doPostInvitation = (websocket, token, channel) => {
    const content = {
        token,
        channel,
        recipientId: channel,
        text: "pending",
        datetime: new Date(), //this.getLocalDatetime(),
        type: "invitation",
    };

    const request = getRequestForm("chat.postMessage", token, content);
    emitRequest(websocket, request);
};

const doPostUserLogin = (websocket, token) => {
    const content = {};
    const request = getRequestForm("auth.login", token, content);

    emitRequest(websocket, request);
};

const doReplyInvitation = (websocket, token, channel, accept, messageId) => {
    const content = {
        channel,
        accept,
        messageId,
        datetime: new Date(),
    };
    const request = getRequestForm("chat.replyInvitation", token, content);

    emitRequest(websocket, request);
};

module.exports = {
    doGetChatList,
    doGetChatHistory,
    doGetUserProfile,
    doResetUnseenCount,
    doPostMessage,
    doPostInvitation,
    doPostUserLogin,
    doReplyInvitation,
};
