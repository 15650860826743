import { useState } from "react";
import AddReactionIcon from "@mui/icons-material/AddReaction";

function EmojiIcon(props) {
    return (
        <a className="dropdown-item align-items-center d-flex" href="#"
            onClick={props.onClick}>{props.emoji}</a>
    );
}

function EmojiList(props) {
    const emojis = ["😅", "😂", "🤔", "🥺", "😯", "👌🏼", "👋🏼", "👍🏼"];

    if (props.open) {
        const style = {
            "position": "absolute",
            "transform": "translate3d(15px, -325px, 0px)",
            "top": "0px",
            "left": "0px",
            "willChange": "transform",
            "minWidth": "1rem",
        };
        return (
            <div id="emoji-list" className="dropdown-menu dropdown-menu-right show"
                style={style} x-placement="top-end">
                {emojis.map((emoji, i) =>
                    <EmojiIcon key={i} emoji={emoji}
                        onClick={() => props.addEmojiToText(emoji)}
                    />)}
            </div>
        );
    } else {
        return (
            <div id="emoji-list" className="dropdown-menu dropdown-menu-right" />
        );
    }
}

export default function EmojiListOpener(props) {
    const [openEmojiList, setOpenEmojiList] = useState(false);

    return (
        <div>
            <a href="#"
                onClick={() => setOpenEmojiList(!openEmojiList)}>
                <AddReactionIcon />
            </a>
            <EmojiList open={openEmojiList} addEmojiToText={props.addEmojiToText} />
        </div>
    );
}
