import { Badge, Box, Grid, Typography } from "@mui/material";

import "../../assets/css/custom.css";
import "../../assets/vendors/jquery-ui/jquery-ui.min.css";
import "../../assets/vendors/jquery-ui/jquery-ui.theme.min.css";
import "../../assets/vendors/bootstrap/css/bootstrap.min.css";
import "../../assets/vendors/feather-font/css/iconfont.css";
import "../../assets/css/navbar.css";
import "../../assets/vendors/fontawesome/css/all.min.css";
import "../../assets/css/main.css";

const unseenGreenDotStyle = {
    "width": "20px",
    "height": "20px",
    "borderRadius": "20px",
    "textAlign": "center",
};

export default function ChatListItemPrivate(props) {
    const {
        profile,
        presence,
    } = props.userInfo;
    const {
        uid,
        profileName,
        imageKey,
        gender,
        lastOnline,
    } = profile;
    const {
        channel,
        channelType,
        lastMessage,
        unseenCount,
    } = props.channelInfo;

    let imagePath = imageKey == null ? `https://b64.homester.hk/assets/img/img.php?t=uicon&filename=null&gender=${gender}` : `https://b64.homester.hk/assets/img/img.php?t=uicon&filename=${imageKey}&gender=${gender}`;

    let lastSeenTime = `Last seen ${new Date(lastOnline).toLocaleString()}`;

    const onlineGreenDot = presence && channelType === "private" ? "green" : "";
    const greenDotClassName = `media d-block d-flex text-left py-2 online-status ${onlineGreenDot}`;
    let selectedLastMessage = lastMessage.type != "stfp" ? lastMessage.text : JSON.parse(lastMessage.text)['message'];

    if (lastMessage.length == 0 || typeof selectedLastMessage === "undefined") {
        selectedLastMessage = "";
    }
    const trimmedLastMessage = selectedLastMessage.length > 20 ? selectedLastMessage.substring(0, 20) + "..." : selectedLastMessage;
    const styleClicked = { "backgroundColor": "#ffffff" };
    const styleNotClicked = {};
    const itemStyle = channel === props.currentChannel ? styleClicked : styleNotClicked;

    let itemName;
    switch (channelType) {
        case "private":
            itemName = profileName;
            break;
        case "group":
            itemName = `Group ${channel}`;
            break;
        default:
            itemName = "[ERROR] channelType";
            break;
    }

    const onClick = () => {
        props.onClick(channel);
    };

    return (
        <div className="nav-item px-3" style={itemStyle} onClick={onClick}>
            <div className={greenDotClassName}>
                <Grid container spacing={1}>
                    <Grid item xs={2.5}>
                        <img className="img-fluid mr-3 rounded-circle user-icon" src={imagePath}
                            alt="" />
                    </Grid>

                    <Grid item xs={9}>
                        <Box>
                            <Typography variant="subtitle2">{itemName}</Typography>
                            <Typography variant="caption">{trimmedLastMessage}</Typography>
                            <br />
                            {channelType === "private" &&
                                <Typography variant="caption">{lastSeenTime}</Typography>}
                        </Box>
                    </Grid>

                    <Grid item xs={0.5}>
                        {unseenCount > 0 &&
                            <Badge badgeContent={unseenCount} color="primary"> </Badge>}
                    </Grid>
                </Grid>

            </div>
        </div>
    );
}
