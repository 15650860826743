const {
    postByAuthChannel,
} = require("./request.handler");

function getGroupPairStatus(token, channel) {
    const directory = "/chatroom/group/pair/status";
    return postByAuthChannel(token, channel, directory);
}

function joinGroupPairing(token, channel) {
    const directory = "/chatroom/group/pair/join";
    return postByAuthChannel(token, channel, directory);
}

function withdrawGroupPairing(token, channel) {
    const directory = "/chatroom/group/pair/withdraw";
    return postByAuthChannel(token, channel, directory);
}

function postCanConfirmGroupPairing(token, channel) {
    const directory = "/chatroom/group/pair/check-confirm";
    return postByAuthChannel(token, channel, directory);
}

function postConfirmGroupPairing(token, channel) {
    const directory = "/chatroom/group/pair/do-confirm";
    return postByAuthChannel(token, channel, directory);
}

export {
    getGroupPairStatus,
    joinGroupPairing,
    withdrawGroupPairing,
    postCanConfirmGroupPairing,
    postConfirmGroupPairing,
};
